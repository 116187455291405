import React, { useState, useEffect } from 'react';
import {
  IonRow,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonCol,
} from '@ionic/react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Spinner from '../../../components/Spinner/Spinner';
import { CURRENCY_TYPE_FACTOR } from '../../../constants/CurrencyTypeFactor';
import { RootState } from '../../../models/RootState';
import { getCurrencyTypeFromToken } from '../../../store';
import SVLS_API from '../../../svls-api';
import { transactionTypes, transactionTypesMap } from '../../../util/stringUtil';

type StoreProps = {
  allowedConfig: number;
  bonusEnabled: boolean;
};

type TurnoverDTO = {
  id: number;
  account_id: number;
  username: string;
  account_path: string;
  transaction_type: string;
  amount: number;
  turnover_balance: number;
  event_date: number;
  event_id: string;
  event_name: string;
  market_type: string;
  market_id: string;
  market_name: string;
  create_time: number;
};

const MyBets: React.FC<StoreProps> = (props) => {
  const { allowedConfig, bonusEnabled } = props;

  const [turnover, setTurnover] = useState<TurnoverDTO[]>([]);
  const [transactionType, setTransactionType] = useState<string>();
  const [currpage, setcurrpage] = useState<number>(1);
  const [dataflag, setdataflag] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortDesc, setsortDesc] = useState<boolean>(true);
  const [dateFrom, setDateFrom] = useState<Moment>(moment().subtract(7, 'd'));
  const [dateTo, setDateTo] = useState<Moment>(moment());

  const pageSize = 25;
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const [pageToken, setPageToken] = useState<string[]>(['']);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [paginationFlag, setPaginationFlag] = useState<boolean>(false);
  const history = useHistory();

  
  useEffect(() => {
    if(!bonusEnabled){
      history.replace('/home')
    }
  }, [bonusEnabled])

  const nextPage = () => {
    setLoading(true);
    if (nextPageToken) {
      setPageToken([...pageToken, nextPageToken]);
    }
  };

  const prevPage = () => {
    setLoading(true);
    setPaginationFlag(true);
    if (pageToken.length > 1) {
      let pagetokens = pageToken;
      pagetokens.pop();
      setPageToken([...pagetokens]);
    }
    setLoading(false);
    setPaginationFlag(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const claims = sessionStorage.getItem('jwt_token').split('.')[1];
      const username = JSON.parse(window.atob(claims)).sub;
      const response = await SVLS_API.get(
        '/marketing/v1/bonus-accounts/turnover-statement',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
            Accept: 'application/json',
          },
          params: {
            pageSize: pageSize,
            pageToken: pageToken[pageToken.length - 1],
            startDate: dateFrom.startOf('day').toISOString(),
            endDate: dateTo.endOf('day').toISOString(),
            username: username,
            transactionType: transactionType,
          },
        }
      );

      setNextPageToken(response.data?.next_page_token);
      setTurnover(response.data?.turnover_entries);
      setdataflag(1);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [sortDesc, dateFrom, dateTo, cFactor, pageToken, transactionType]);

  const fromDateChangeHandler = (d: Moment) => {
    setDateFrom(d);
  };

  const toDateChangeHandler = (d: Moment) => {
    setDateTo(d);
  };

  return (
    <>
      <IonRow className="h-100">
        <IonCol>
          <div className="reports-ctn my-bets-ctn">
            <div className="header-ctn">
              <IonRow className="header-ctn-row">
                <div className="img-page-title-row">
                  <div className="title">Turnover History</div>
                </div>
                <div className="title-border-line"></div>
              </IonRow>

              <div className="filters-row">
              <div className="select-filter">
                    <div className=" select-filter-label">Transaction Type</div>
                    <IonSelect
                      value={transactionType}
                      placeholder="Select One"
                      interface="popover"
                      onIonChange={(e) => setTransactionType(e.detail.value)}
                    >
                      <IonSelectOption className="text-light" value="">
                        All
                      </IonSelectOption>
                      {transactionTypes?.map((type) => (
                        <IonSelectOption
                          className="text-light"
                          value={type?.value}
                        >
                          {type?.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
              <div className="date-section">
                <div className="date-filter mr-11">
                  <div className="date-filter-label">From</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      minDate={moment().subtract(1, 'months').calendar()}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="from-date"
                      value={dateFrom}
                      onChange={(e) => fromDateChangeHandler(e)}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="date-filter">
                  <div className="date-filter-label">To</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      minDate={moment().subtract(1, 'months').calendar()}
                      maxDate={moment()}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="to-date"
                      value={dateTo}
                      onChange={(e) => toDateChangeHandler(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            </div>
            <div className="content-ctn light-bg my-bets-content">
              <div className="myb-bets-div">
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="tbl-ctn my-bets-tbl no-hov-style">
                      <TableContainer component={Paper}>
                        <Table className="myb-table" size="small">
                          <TableHead className="myb-table-header">
                            <TableRow>
                              <TableCell className="th-date">
                                Event Date
                              </TableCell>
                              <TableCell align="left" className="th-match">
                                Transaction Type
                              </TableCell>
                              <TableCell align="left" className="th-market">
                                Match
                              </TableCell>
                              <TableCell align="left" className="th-bet-on">
                                Market
                              </TableCell>
                              <TableCell align="right">Market Type</TableCell>
                              <TableCell align="right">Amount</TableCell>
                              <TableCell align="left" className="th-outcome">
                                Turnover Balance
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {turnover.length > 0 || dataflag === 0 ? (
                            <TableBody className="myb-table-body">
                              {turnover.map((row) => (
                                <Row key={row.id} row={row} />
                              ))}
                            </TableBody>
                          ) : (
                            <TableCell className="no-data-row" colSpan={9}>
                              <div className="no-bets-msg">No Bets Found</div>
                            </TableCell>
                          )}
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
                <IonRow>
                  {pageToken.length === 1 || loading ? null : (
                    <IonButton
                      className="myb-btn-prev"
                      onClick={(e) => prevPage()}
                      disabled={pageToken.length < 2 || paginationFlag}
                    >
                      Prev({pageToken.length - 1})
                    </IonButton>
                  )}
                  {nextPageToken && !loading ? (
                    <IonButton
                      className="myb-btn-next"
                      onClick={(e) => nextPage()}
                      disabled={
                        nextPageToken === '' ||
                        nextPageToken === null ||
                        paginationFlag
                      }
                    >
                      Next({pageToken.length + 1})
                    </IonButton>
                  ) : null}
                </IonRow>
              </div>
            </div>
          </div>
        </IonCol>
      </IonRow>
    </>
  );
};

function Row(props: { row: TurnoverDTO }) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow className={'myb-table-row'}>
        <TableCell className="td-date" component="th" scope="row">
          {moment(row.event_date).format('DD-MM-YY, h:mm:ss A')}
        </TableCell>
        <TableCell className="myb-table-cell td-market" align="left">
          <span className="mob-fs-14">
            {transactionTypesMap[row.transaction_type]}
          </span>
        </TableCell>
        <TableCell className="myb-table-cell td-bet-on" align="left">
          {row.event_name}
        </TableCell>
        <TableCell className="myb-table-cell td-stake" align="left">
          <span className="mob-fs-14">{row.market_name}</span>
        </TableCell>
        <TableCell className="myb-table-cell td-outcome" align="left">
          {row.market_type}
        </TableCell>
        <TableCell className={'myb-table-cell'} align="right">
          {row.amount}
        </TableCell>
        <TableCell className={'myb-table-cell'} align="right">
          <span className="mob-fs-14">{row.turnover_balance}</span>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    allowedConfig: state.common.allowedConfig,
    bonusEnabled: state.common.domainConfig.bonus,
  };
};

export default connect(mapStateToProps, null)(MyBets);