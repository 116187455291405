import React, { useEffect, useState } from 'react';
import { IonRow, IonIcon, IonCol, IonButton } from '@ionic/react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './BonusStatement.scss'
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import SVLS_API from '../../../svls-api';
import Spinner from '../../../components/Spinner/Spinner';
import { RootState } from '../../../models/RootState';

type Props = {};

type options = { name: string; value: string };

type UserAccProps = {
  userName?: any;
  userId?: any;
};

type BonusProps = {
  award_amount: number;
  award_date: Moment;
  bonus_status: string;
  bonus_category: string;
  id: number;
  last_vest_date: Moment;
  notes: string;
  redeemed_amount: number;
  last_redeem_date: Moment;
  redemptions: RedemptionDTO[];
  installments_given: number;
  installments: number;
  approval_required: boolean;
  turnover_required: number;
  turnover_met: number;
};

type RedemptionDTO = {
  notes: string;
  redeem_amount: number;
  redeem_date: Moment;
  redemption_id: number;
  status: string;
};

const BonusStatement: React.FC<{ bonusEnabled: boolean }> = (props) => {
  const { bonusEnabled } = props;
  const [fromDate, setfromDate] = useState<Moment>(moment().subtract(7, 'd'));
  const [toDate, setToDate] = useState<Moment>(moment());
  const history = useHistory();
  const [showTxnLevelView, setShowTxnLevelView] = useState<boolean>(false);
  const [bonusType, setBonusType] = useState<string>('All');
  const [bonusStatus, setBonusStatus] = useState<string>('All');
  const [loading, setLoading] = useState<boolean>(true);
  const [bonusData, setBonusData] = useState<BonusProps[]>();
  const [awardBonusModal, setAwardBonusModal] = useState<boolean>(false);
  const [updateBonusModal, setUpdateBonusModal] = useState<boolean>(false);
  const [vestBonusModal, setVestBonusModal] = useState<boolean>(false);
  const [pageToken, setPageToken] = useState<string[]>(['']);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [paginationFlag, setPaginationFlag] = useState<boolean>(false);
  const [deleteBonusId, setDeleteBonusId] = useState<Number>(-1);
  const [expireBonusId, setExpireBonusId] = useState<Number>(-1);
  const [vestDeleteId, setVestDeleteId] = useState({
    vestId: -1,
    bonusId: -1,
  });
  const [updateBonusId, setUpdateBonusId] = useState<Number>(-1);
  const [vestBonusId, setVestBonusId] = useState<Number>(-1);
  const [pageNum, setPageNum] = useState<number>(1);
  const [open, setOpen] = React.useState<any>({
    bonusId: -1,
    open: false,
  });
  const [notes, setNotes] = useState<string>();

  const pageSize = 25;

  const bonusTypeOptions: options[] = [
    { value: 'Joining Bonus', name: 'Joining Bonus' },
    { value: 'Deposit Bonus', name: 'Deposit Bonus' },
  ];

  const bonusStatusOptions: options[] = [
    { value: 'Awarded', name: 'Awarded' },
    { value: 'Partially Redeemed', name: 'Partially Redeemed' },
    { value: 'Redeemed', name: 'Redeemed' },
    { value: 'Expired', name: 'Expired' },
  ];

  const nextPage = () => {
    setLoading(true);
    setPageNum(pageNum + 1);
    if (nextPageToken !== null && nextPageToken !== '') {
      setPageToken([...pageToken, nextPageToken]);
    }
  };

  const prevPage = () => {
    setLoading(true);
    setPageNum(pageNum - 1);
    setPaginationFlag(true);
    if (pageToken?.length > 1) {
      let pagetokens = pageToken;
      pagetokens.pop();
      setPageToken([...pagetokens]);
    }
    setLoading(false);
    setPaginationFlag(false);
  };

  const getBonusData = async () => {
    try {
      setLoading(true);
      const claims = sessionStorage.getItem('jwt_token').split('.')[1];
      const userId = JSON.parse(window.atob(claims)).aid;

      const response = await SVLS_API.get('marketing/v1/bonuses/', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
        params: {
          accountId: userId,
          bonusStatus: bonusStatus === 'All' ? null : bonusStatus,
          bonusType: bonusType === 'All' ? null : bonusType,
          startDate: fromDate.startOf('day').toISOString(),
          endDate: toDate.endOf('day').toISOString(),
          pageToken: pageToken[pageToken?.length - 1],
          pageSize: pageSize,
        },
      });
      setNextPageToken(response.data.next_page_token);
      setBonusData(response.data.bonuses);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if(!bonusEnabled){
      history.replace('/home')
    }
  }, [bonusEnabled])
  
  useEffect(() => {
    getBonusData();
  }, [bonusType, bonusStatus, fromDate, toDate, pageToken]);

  const fromDateChangeHandler = (d: Moment) => {
    setfromDate(d);
  };

  const toDateChangeHandler = (d: Moment) => {
    setToDate(d);
  };
  return (
    <IonRow className="h-100">
      <IonCol>
        <div className="reports-ctn my-bets-ctn">
          <div className="header-ctn">
            <IonRow className="header-ctn-row">
              <div className="img-page-title-row">
                <div className="title">Bonus Statement</div>
              </div>
              <div className="title-border-line"></div>
            </IonRow>

            <div className="filters-row">
              <div className="date-section">
                <div className="date-filter mr-11">
                  <div className="date-filter-label">From</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      minDate={moment().subtract(1, 'months').calendar()}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="from-date"
                      value={fromDate}
                      onChange={(e) => fromDateChangeHandler(e)}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="date-filter">
                  <div className="date-filter-label">To</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      minDate={moment().subtract(1, 'months').calendar()}
                      maxDate={moment()}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="to-date"
                      value={toDate}
                      onChange={(e) => toDateChangeHandler(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
          <div className="content-ctn light-bg my-bets-content">
            <div className="myb-bets-div">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <div className="tbl-ctn my-bets-tbl no-hov-style">
                    <TableContainer component={Paper}>
                      <Table className="myb-table" size="small">
                        <TableHead className="myb-table-header">
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="left" className="th-col">
                              Bonus Type
                            </TableCell>
                            <TableCell align="left" className="th-col">
                              Approval Required
                            </TableCell>
                            <TableCell align="left" className="th-col">
                              Awarded Date
                            </TableCell>
                            <TableCell align="left" className="th-col">
                              Awarded Amount
                            </TableCell>
                            <TableCell align="center" className="th-col">
                              Turnover
                            </TableCell>
                            <TableCell align="center" className="th-col">
                              Installments
                            </TableCell>
                            <TableCell align="left" className="th-col">
                              Redeemed Amount
                            </TableCell>
                            <TableCell align="center" className="th-col">
                              Bonus Status
                            </TableCell>
                            <TableCell align="left" className="th-col">
                              Last Redeemed Date
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {bonusData?.length > 0 ? (
                          <TableBody className="myb-table-body">
                            {bonusData.map((row, idx) => (
                              <>
                                <TableRow key={'row-' + idx}>
                                  <TableCell>
                                    {row.redemptions?.length > 0 && (
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() =>
                                          setOpen({
                                            bonusId: row.id,
                                            open:
                                              row.id == open.bonusId
                                                ? !open.open
                                                : true,
                                          })
                                        }
                                      >
                                        {open.open &&
                                        open.bonusId === row.id ? (
                                          <KeyboardArrowUpIcon
                                            htmlColor={'#000'}
                                          />
                                        ) : (
                                          <KeyboardArrowDownIcon
                                            htmlColor={'#000'}
                                          />
                                        )}
                                      </IconButton>
                                    )}
                                  </TableCell>
                                  <TableCell key={'row-' + idx + '-cell-3'}>
                                    <div className="b-text m-link">
                                      {row.bonus_category}
                                    </div>
                                  </TableCell>
                                  <TableCell key={'row-' + idx + '-cell-3'}>
                                    <div className="b-text m-link">
                                      {row.approval_required ? 'Yes' : 'No'}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    key={'row-' + idx + '-cell-1'}
                                    component="th"
                                  >
                                    {moment(row.award_date).format(
                                      'DD/MM/YYYY, h:mm:ss A'
                                    )}
                                  </TableCell>
                                  <TableCell key={'row-' + idx + '-cell-7'}>
                                    {row?.award_amount?.toFixed(2)}
                                  </TableCell>

                                  <TableCell
                                    key={'row-' + idx + '-cell-8'}
                                    align="center"
                                  >
                                    {(row.turnover_met
                                      ? row.turnover_met
                                      : '-') +
                                      '/' +
                                      (row.turnover_required
                                        ? row.turnover_required
                                        : '-')}
                                  </TableCell>
                                  <TableCell
                                    key={'row-' + idx + '-cell-10'}
                                    align="center"
                                  >
                                    {(row.installments_given
                                      ? row.installments_given
                                      : '-') +
                                      '/' +
                                      (row.installments
                                        ? row.installments
                                        : '-')}
                                  </TableCell>

                                  <TableCell key={'row-' + idx + '-cell-7'}>
                                    {row?.redeemed_amount?.toFixed(2)}
                                  </TableCell>
                                  <TableCell
                                    key={'row-' + idx + '-cell-8'}
                                    align="center"
                                  >
                                    {row.bonus_status}
                                  </TableCell>

                                  <TableCell
                                    key={'row-' + idx + '-cell-2'}
                                    component="th"
                                  >
                                    {row.last_redeem_date
                                      ? moment(row.last_redeem_date).format(
                                          'DD/MM/YYYY, h:mm:ss A'
                                        )
                                      : '-'}
                                  </TableCell>
                                </TableRow>
                                {open.open && row?.id === open.bonusId && (
                                  <TableRow>
                                    <TableCell
                                      className="pb-0-pt-0 "
                                      colSpan={12}
                                    >
                                      <TableContainer component={Paper}>
                                        <Table>
                                          <TableHead className="redeem-row-ctn">
                                            <TableCell colSpan={3}>
                                              Redeemed Date
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                              Amount
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                              Status
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                              Notes
                                            </TableCell>
                                          </TableHead>
                                          {row?.redemptions?.map((redeem) => (
                                            <>
                                              <TableBody>
                                                <TableCell colSpan={3}>
                                                  {moment(
                                                    redeem.redeem_date
                                                  ).format(
                                                    'DD/MM/YYYY, h:mm:ss A'
                                                  )}
                                                </TableCell>
                                                <TableCell colSpan={3}>
                                                  {redeem?.redeem_amount?.toFixed(
                                                    2
                                                  )}
                                                </TableCell>
                                                <TableCell colSpan={3}>
                                                  {redeem?.status
                                                    ? redeem?.status
                                                    : '-'}
                                                </TableCell>
                                                <TableCell colSpan={3}>
                                                  {redeem.notes
                                                    ? redeem.notes
                                                    : '-'}
                                                </TableCell>
                                                {/* <TableCell colSpan={3} align='center'>
                                                    <Button title='Delete' className="delete-bonus bonus-action-btn"
                                                      disabled={row.bonus_status === "Expired"}
                                                      onClick={() => setVestDeleteId({
                                                        vestId: redeem?.redemption_id,
                                                        bonusId: row?.id
                                                      })}>
                                                      <DeleteForeverOutlined />
                                                    </Button>
                                                  </TableCell> */}
                                              </TableBody>
                                            </>
                                          ))}
                                        </Table>
                                      </TableContainer>
                                    </TableCell>{' '}
                                  </TableRow>
                                )}
                              </>
                            ))}
                          </TableBody>
                        ) : (
                          <TableCell className="no-data-row" colSpan={9}>
                            <div> You have 0 Transactions</div>
                          </TableCell>
                        )}
                      </Table>
                    </TableContainer>
                  </div>
                </>
              )}
              <IonRow>
                {pageToken.length === 1 || loading ? null : (
                  <IonButton
                    className="myb-btn-prev"
                    onClick={(e) => prevPage()}
                    disabled={pageToken.length < 2}
                  >
                    Prev({pageToken.length - 1})
                  </IonButton>
                )}
                {nextPageToken && !loading ? (
                  <IonButton
                    className="myb-btn-next"
                    onClick={(e) => nextPage()}
                    disabled={!nextPageToken}
                  >
                    Next({pageToken.length + 1})
                  </IonButton>
                ) : null}
              </IonRow>
            </div>
          </div>
        </div>
      </IonCol>
    </IonRow>
  );
};


const mapStateToProps = (state: RootState) => {
  return {
    bonusEnabled: state.common.domainConfig.bonus,
  };
};

export default connect(mapStateToProps)(BonusStatement);