import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import API from '../../api';
import { TransactionDetails } from '../../models/TransactionDetails';
import { MarketTypeMap, OutcomeDescMap } from '../../util/stringUtil';
import './AccountStatementRecord.scss';
import { format } from 'date-fns';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import { getCurrencyTypeFromToken } from '../../store';
import { TableHead, IconButton } from '@material-ui/core';
import { UserBet } from '../../models/UserBet';
import moment from 'moment';
import { LedgerRecord } from '../../models/LedgerRecord';

type Props = {
  ledgerRecord: LedgerRecord;
};

const AccountStatementRecord: React.FC<Props> = (props) => {
  const { ledgerRecord } = props;

  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetails>();
  const [betList, setBetList] = useState<UserBet[]>([]);
  const [error, setError] = useState<boolean>(false);
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const fetchData = async () => {
    try {
      const response = await API.post(
        '/sap/reports/transaction-details',
        null,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            transId: ledgerRecord.transactionId,
            transType: ledgerRecord.transactionType,
            eventId: ledgerRecord.eventId,
            marketId: ledgerRecord.marketId,
          },
        }
      );
      if (response.status === 200 && response.data) {
        setBetList(response.data);
      } else {
        setError(true);
        console.log(response);
      }
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  const featchSAPBetList = async () => {
    try {
      const response = await API.get('/sap/reports/event-bets', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
        params: {
          // transId: ledgerRecord.transactionId,
          // transType: ledgerRecord.transactionType,
          // eventId: ledgerRecord.eventId,
          // marketId: ledgerRecord.marketId,
          // marketType: ledgerRecord.marketType,
          transId: ledgerRecord.transactionId,
          transType: ledgerRecord.transactionType,
          roundId: ledgerRecord.marketId,
        },
      });
      if (response.status === 200 && response.data) {
        setBetList(response.data);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    // if (Number(ledgerRecord.transactionType) > 25) {
    //   featchSAPBetList();
    // } else {
    fetchData();
    // }
  }, []);

  return (
    <div className="transaction-details-ctn reports-ctn">
      <>
        <div className="content-ctn">
          <div className="tbl-ctn">
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead className="tbl-header-section">
                  {![0, 1, 2, 3].includes(+ledgerRecord.transactionType) ? (
                    <TableRow>
                      <TableCell className="date-col">Date</TableCell>
                      <TableCell align="left">Event</TableCell>
                      <TableCell align="left">Market Type</TableCell>
                      <TableCell align="left">Bet on</TableCell>
                      <TableCell align="left">Odds</TableCell>
                      <TableCell align="left">Stake</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Returns</TableCell>{' '}
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell className="date-col">Date</TableCell>
                      <TableCell align="left">Transaction</TableCell>
                      <TableCell align="left">Balance Before</TableCell>
                      <TableCell align="left">Balance After</TableCell>
                      <TableCell align="left">Amount</TableCell>
                    </TableRow>
                  )}
                </TableHead>
                {betList.length > 0 ? (
                  <TableBody className="myb-table-body">
                    {betList.map((row) => (
                      <Row
                        key={row.id}
                        row={row}
                        isGameReport={
                          [0, 1, 2, 3, 45, 46].includes(+ledgerRecord.transactionType)
                            ? false
                            : true
                        }
                      />
                    ))}
                  </TableBody>
                ) : (
                  <TableCell className="no-data-row" colSpan={8}>
                    <div>
                      {' '}
                      You have no transactions for the selected criteria
                    </div>
                  </TableCell>
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
      </>
    </div>
  );
};

export default AccountStatementRecord;

function Row(props: { row: UserBet; isGameReport: boolean }) {
  const { row, isGameReport } = props;
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  return (
    <React.Fragment>
      {isGameReport ? (
        <TableRow
          className={
            (row.oddValue && row.betType === 0) || row.eventName === 'CASINO'
              ? 'myb-table-row back-odd-row'
              : row.oddValue && row.betType === 1
                ? 'myb-table-row lay-odd-row'
                : 'myb-table-row'
          }
        >
          <TableCell component="th" scope="row">
            {moment(row.transactionTime).format('DD-MM-YY, h:mm:ss A')}
          </TableCell>

          <TableCell className="myb-table-cell td-match" align="left">
            <span className="event-link">{row.eventName}</span>
          </TableCell>

          <TableCell align="left">{MarketTypeMap[row.marketType]}</TableCell>
          <TableCell align="left">
            {row.marketType === 2
              ? row.marketName +
              ' @ ' +
              Number(row.oddValue * 100 - 100).toFixed(0)
              : row.marketType === 5
                ? row.outcomeDesc + ' @ ' + Number(row.oddValue).toFixed(0)
                : row.outcomeDesc}
          </TableCell>
          <TableCell className="myb-table-cell td-odd" align="left">
            <span className="mob-fs-13">
              {row.oddValue && row.oddValue !== -1.0
                ? row.marketType === 2
                  ? Number(row.marketResult).toFixed(2)
                  : row.marketType === 1
                    ? (row.oddValue * 100 - 100).toFixed(2)
                    : row.marketType === 5
                      ? (2).toFixed(2)
                      : row.oddValue.toFixed(2)
                : '-'}
            </span>
          </TableCell>
          <TableCell className="myb-table-cell td-stake" align="left">
            <span className="mob-fs-14">{row.stakeAmount / cFactor}</span>
          </TableCell>
          <TableCell align="left">{OutcomeDescMap[row.betResult]}</TableCell>
          <TableCell
            className={
              row.payOutAmount > 0
                ? 'myb-table-cell profit-bet'
                : 'myb-table-cell loss-bet'
            }
            align="left"
          >
            <span className="mob-fs-14">
              {(row.profitAmount / cFactor).toFixed(2)}
            </span>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow className={'myb-table-row'}>
          <TableCell component="th" align="left" scope="row">
            {moment(row.transactionTime).format('DD-MM-YY, h:mm:ss A')}
          </TableCell>

          <TableCell className="myb-table-cell td-match" align="left">
            {row.fromUser}
          </TableCell>

          <TableCell className="myb-table-cell td-stake" align="left">
            <span className="mob-fs-14">
              {(row.balanceBefore / cFactor).toFixed(2)}
            </span>
          </TableCell>
          <TableCell align="left">{row.balanceAfter / cFactor}</TableCell>
          <TableCell align="left">
            {(row.profitAmount / cFactor).toFixed(2)}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
