import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import { IonButtons, IonImg } from '@ionic/react';
import {
  AppBar,
  Button,
  Drawer,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core';
import './Header.scss';
import Modal from '../Modal/Modal';
import LoginModal from '../Modal/LoginModal';
import LoginPage from '../LoginForm/LoginPage';
import { RootState } from '../../models/RootState';
import {
  fetchBalance,
  logout,
  toggleDarkMode,
  getCurrencyTypeFromToken,
  overwriteBalacne,
  setOpenDepositModal,
  setOpenWithdrawModal,
} from '../../store';
import ChangePwdForm from '../ChangePassword/ChangePassword';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import { useInterval } from '../../hooks/useInterval';
import moment from 'moment';
import logo from '../../assets/images/brand/title.png';
import User from '../../assets/images/home/homeview/user.png';
import { ReactComponent as Balance } from '../../assets/images/home/homeview/Balance.svg';
import { ReactComponent as DownArrow } from '../../assets/images/home/homeview/downArrow.svg';
import { ReactComponent as Terms } from '../../assets/images/home/homeview/terms.svg';
import { ReactComponent as GameRule } from '../../assets/images/home/homeview/gameRule.svg';
import SVLS_API from '../../svls-api';
import { BRAND_DOMAIN } from '../../constants/Branding';
import WhatsupImg from '../../assets/images/header/whatsup-flot.svg';
import { CloseOutlined } from '@material-ui/icons';
import Withdrawal from '../../pages/Payment/Withdrawal';
import Deposit from '../../pages/Payment/Deposit';
import { DomainConfig } from '../../constants/DomainConfig';
import { domain } from 'process';

type StoreProps = {
  balance: number;
  bonus:number;
  exposure: number;
  allowedConfig: number;
  commissionEnabled: boolean;
  fetchBalance: Function;
  loggedIn: boolean;
  currenciesAllowed: number;
  tvGamesEnabled: boolean;
  prefersDark: string;
  toggleDarkMode: (val: string) => void;
  overwriteBalacne: () => void;
  logout: () => void;
  openDepositModal: boolean;
  openWithdrawModal: boolean;
  setOpenDepositModal: Function;
  setOpenWithdrawModal: Function;
  domainConfig: DomainConfig;
};

const Header: React.FC<StoreProps> = (props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [refreshingBalance, setRefreshingBalance] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  const {
    balance,
    bonus,
    exposure,
    allowedConfig,
    fetchBalance,
    loggedIn,
    logout,
    currenciesAllowed,
    prefersDark,
    overwriteBalacne,
    openDepositModal,
    openWithdrawModal,
    setOpenDepositModal,
    setOpenWithdrawModal,
    domainConfig,
  } = props;

  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const [tabValue, setTabValue] = useState<number>();
  const [localTime, setLocalTime] = useState(moment().format('hh:mm:ss A'));
  const history = useHistory();
  const [amdinWhatsAppNumber, setAmdinWhatsAppNumber] = useState<string>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showDeposit, setShowDeposit] = useState<boolean>(true);
  const [showWithdrawal, setShowWithdrawal] = useState<boolean>(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAdminWhatsAppNumber();
  }, []);

  const loginHandler = () => {
    setModalOpen(true);
    setShowPopover({ open: false, event: undefined });
  };

  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  const handlePopoverClose = () => {
    setShowPopover({ open: false, event: undefined });
  };

  const logoutHandler = () => {
    logout();
    setShowPopover({ open: false, event: undefined });
  };

  const getAdminWhatsAppNumber = async () => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/books/${BRAND_DOMAIN}`,
        {}
      );
      if (response.status === 200) {
        setAmdinWhatsAppNumber(response?.data?.contact?.whatsappNumber);
      }
    } catch (err) {}
  };

  useInterval(() => {
    setLocalTime(moment().format('hh:mm:ss A'));
  }, 1000);

  // const getPaymentProviders = async () => {
  //   const accountId = sessionStorage.getItem('aid');
  //   try {
  //     const response = await AGPAY_API.get(
  //       `/agpay/v2/payment-settings/accounts/${accountId}`,
  //       {
  //         headers: {
  //           Authorization: sessionStorage.getItem('jwt_token'),
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       if (response?.data?.withdraw_provider_list && response?.data?.withdraw_provider_list?.length > 0) {
  //         setShowWithdrawal(true);
  //       } else {
  //         setShowWithdrawal(false);
  //       }

  //       if (response?.data?.deposit_provider_list && response?.data?.deposit_provider_list?.length > 0) {
  //         setShowDeposit(true);
  //       } else {
  //         setShowDeposit(false);
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    console.log("domain",domainConfig);
    
  }, [domainConfig])
  
  return (
    <>
      <AppBar position="static" className={'main-header-ctn'}>
        <IonButtons className="logo-ctn">
          <IonImg
            className="logo"
            src={logo}
            onClick={() => history.push('/home')}
          />
        </IonButtons>
        <Toolbar className="toolbar" variant="dense">
          <div className="header-actions">
            {domainConfig.whatsapp ? (
              <Button className="actions-btn whatsapp-btn">
                <div className="btn-content">
                  <a
                    href={`http://api.whatsapp.com/send?phone=${amdinWhatsAppNumber}`}
                    target="_blank"
                  >
                    <img src={WhatsupImg} className="social-icon1" />
                  </a>
                </div>
              </Button>
            ) : null}

            {loggedIn ? (
              <>
                <div className="header-right-actions">
                  {domainConfig.payments ? (
                    <>
                      {
                        showDeposit ?
                          <Button className='signup-btn web-view' onClick={() => setOpenDepositModal(true)}>Deposit</Button>
                          : null
                      }
                      {
                        showWithdrawal ?
                          <Button className='signup-btn web-view' onClick={() => setOpenWithdrawModal(true)}>Withdraw</Button>
                          : null
                      }
                    </>
                  ) : null}

                  <Button className="signup-btn balance-btn" size="small">
                    <div className='balance-widget'>
                    <Balance height={18} width={24} style={{ paddingRight: '7.5px' }} />{' '}
                    <div>{balance !== null
                      ? balance.toFixed(2)
                      : '-.--'}{' '}</div>
                      </div>
                        {
                          domainConfig.bonus?(
                              <div>
                              Bonus: { 
                                  bonus!==null
                                  ? bonus.toFixed(2)
                                  : '-.-'
                                }
                              </div>
                          ):null
                        }
                  </Button>

                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    className="login-btn p-0"
                    disableElevation
                    onClick={handleClick}
                    // endIcon={<KeyboardArrowDownIcon />}
                  >
                    <img src={User} className="user-img-border" />
                    <span className='username-text'>{sessionStorage.getItem('username')}

                    </span>
                    <DownArrow className="downArrow" />
                  </Button>
                  <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {/* <MenuItem className='menu-item-text' onClick={handleClose} disableRipple>
                      <PlayHistory />
                      Play History
                    </MenuItem>
                    <div className="menu-border-bottom"></div> */}

                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/responsible-gambling');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <GameRule />
                      <span>Game Rules</span>
                    </MenuItem>
                    <div className="menu-border-bottom"></div>

                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/account/my_bets');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      My Bets
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/account/my_wallet');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      My Wallet
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/account/account_statement');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      Acc Statement
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/account/pl_statement');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      PL Statement
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    {
                      domainConfig.bonus ?

                      (
                        <>
                          <MenuItem className='menu-item-text' onClick={() => {
                            history.push('/bonus_statement');
                            handleClose();
                          }} disableRipple>
                            <Terms />
                            Bonus Statement
                          </MenuItem>
                            <div className="menu-border-bottom"></div>
                          <MenuItem className='menu-item-text' onClick={() => {
                            history.push('/bonus/turnover_history');
                            handleClose();
                          }} disableRipple>
                            <Terms />
                            Turnover history
                          </MenuItem>
                        </>
                      )
                      :null
                    }

                    {domainConfig.payments ? (
                      <>
                        {showWithdrawal || showDeposit ? (
                          <>
                            <div className="menu-border-bottom"></div>
                            <MenuItem
                              className="menu-item-text"
                              onClick={() => {
                                history.push('/my_transactions');
                                handleClose();
                              }}
                              disableRipple
                            >
                              <Terms />
                              My Transactions
                            </MenuItem>
                          </>
                        ) : null}
                      </>
                    ) : null}

                    <div className="menu-border-bottom"></div>
                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/terms-conditions');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      T&C
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <Button onClick={logout} className="logout-btn">
                      Logout
                    </Button>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <div className="header-right-actions">
                  <Button
                    className="login-btn"
                    onClick={() => {
                      loginHandler();
                      setTabValue(0);
                    }}
                    size="small"
                  >
                    Login
                  </Button>

                  {domainConfig.signup ? (
                    <Button
                      slot="end"
                      className="signup-btn"
                      onClick={() => {
                        loginHandler();
                        setTabValue(1);
                      }}
                    >
                      Sign Up
                    </Button>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {/* <NotificationPage /> */}

      <Backdrop className="backdrop-ctn" open={modalOpen}>
        <LoginModal
          open={modalOpen}
          closeHandler={modalCloseHandler}
          noTitle={true}
          size="md"
          customClass={'login-modal-ctn'}
        >
          <LoginPage
            modalCloseHandler={modalCloseHandler}
            tabActive={tabValue}
          ></LoginPage>
        </LoginModal>
      </Backdrop>

      <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
        <Modal
          open={changePwdMOdal}
          closeHandler={() => setChangePwdMOdal(false)}
          title="Change Password"
          size="xs"
        >
          <ChangePwdForm
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => {}}
          />
        </Modal>
      </Backdrop>

      <Backdrop className="backdrop-ctn" open={openDepositModal}>
        <Drawer
          anchor={'right'}
          open={openDepositModal}
          onClose={() => setOpenDepositModal(false)}
          className="light-bg-title game-rules-drawer"
          title=""
          // size="md"
        >
          <div className="game-rules-header">
            <div className="game-rules-title">Deposit</div>
            <div
              className="game-rules-close cursor"
              onClick={() => setOpenDepositModal(false)}
            >
              <CloseOutlined />
            </div>
          </div>
          <Deposit />
        </Drawer>
      </Backdrop>
      <Backdrop className="backdrop-ctn" open={openWithdrawModal}>
        <Drawer
          anchor={'right'}
          open={openWithdrawModal}
          onClose={() => setOpenWithdrawModal(false)}
          className="light-bg-title game-rules-drawer"
          title=""
          // size="md"
        >
          <div className="game-rules-header">
            <div className="game-rules-title">Withdraw</div>
            <div
              className="game-rules-close cursor"
              onClick={() => setOpenWithdrawModal(false)}
            >
              <CloseOutlined />
            </div>
          </div>
          <Withdrawal />
        </Drawer>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    balance: state.auth.balanceSummary.balance,
    bonus: state.auth.balanceSummary.bonus,
    exposure: state.auth.balanceSummary.exposure,
    allowedConfig: state.common.allowedConfig,
    loggedIn: state.auth.loggedIn,
    commissionEnabled: state.common.commissionEnabled,
    currenciesAllowed: state.common.currenciesAllowed,
    tvGamesEnabled: state.common.tvGamesEnabled,
    prefersDark: state.common.prefersDark,
    openDepositModal: state.auth.openDepositModal,
    openWithdrawModal: state.auth.openWithdrawModal,
    domainConfig: state.common.domainConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    overwriteBalacne: () => dispatch(overwriteBalacne()),
    logout: () => dispatch(logout()),
    toggleDarkMode: (val: string) => dispatch(toggleDarkMode(val)),
    setOpenDepositModal: (val) => dispatch(setOpenDepositModal(val)),
    setOpenWithdrawModal: (val) => dispatch(setOpenWithdrawModal(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
function alpha(white: any, arg1: number) {
  throw new Error('Function not implemented.');
}
