import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment, { Moment } from 'moment';
import API from '../../api/index';
import { TransactionsResponse } from '../../models/TransactionsResponse';
import { AuthResponse } from '../../models/api/AuthResponse';
import Spinner from '../../components/Spinner/Spinner';
import Alert from '../../components/Alert/Alert';
import { RootState } from '../../models/RootState';
import {
  logout,
  getCurrencyTypeFromToken,
  setOpenDepositModal,
  setOpenWithdrawModal,
} from '../../store';
import './TransactionRequest.scss';
import {
  Backdrop,
  Button,
  Drawer,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core';
import SelectSearch from 'react-select-search';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import {
  OfflineTransactionStatusTypeMap,
  OfflineTransactionTypeMap,
} from '../../util/stringUtil';
import {
  IonRow,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonCol,
  IonIcon,
} from '@ionic/react';
import AGPAY_API from '../../api-services/agpay-api';
import { CloseOutlined } from '@material-ui/icons';
import Deposit from '../Payment/Deposit';
import Withdrawal from '../Payment/Withdrawal';
import { useHistory } from 'react-router';
import { eye } from 'ionicons/icons';

type options = { name: string; value: string };

type TransactionProps = {
  openDepositModal: boolean;
  openWithdrawModal: boolean;
  setOpenDepositModal: Function;
  setOpenWithdrawModal: Function;
};

const TransactionMap = {
  IN_PROGRESS: 'In Progress',
  APPROVAL_PENDING: 'Approval Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

const TransactionRequest: React.FC<TransactionProps> = (props) => {
  const {
    openDepositModal,
    openWithdrawModal,
    setOpenDepositModal,
    setOpenWithdrawModal,
  } = props;
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const [transactionRequest, setTransactionRequest] = useState<
    TransactionsResponse[]
  >([]);
  const [fromDate, setfromDate] = useState<Moment>(moment().subtract(3, 'd'));
  const [toDate, setToDate] = useState<Moment>(moment());
  const [transactionStatus, setTransactionStatus] = useState<string>('');
  const [transactionType, setTransactionType] = useState<string>('');
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const [currpage, setcurrpage] = useState<number>(1);
  const pageSize = 25;
  const [pageToken, setPageToken] = useState<string[]>(['']);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [sectionTabVal, setSectionTabVal] = useState(0);
  // const [openDepositModal, setOpenDepositModal] = useState(false);
  // const [openWithdrawModal, setOpenWithdrawModal] = useState(false);

  const fetchTransactionRequest = useCallback(async () => {
    setLoading(true);
    setErrorMsg(null);

    try {
      const response: AuthResponse = await AGPAY_API.get(
        '/agpay/v2/transactions/:search',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            startDate: fromDate.startOf('day').toISOString(),
            endDate: toDate.endOf('day').toISOString(),
            pageSize: pageSize,
            pageToken: pageToken[pageToken.length - 1],
            status: transactionStatus,
            type: transactionType,
            isAscending: false,
          },
        }
      );
      if (response.status === 200) {
        const items = response.data.transactions;
        setNextPageToken(response.data.nextPageToken);
        for (const item of items) {
          item.amount = item.amount / cFactor;
        }
        setTransactionRequest(items);
        console.log(items.transactions);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
      setLoading(false);
    }
  }, [fromDate, toDate, transactionStatus, transactionType, pageToken]);

  const nextPage = () => {
    setLoading(true);
    setcurrpage(currpage + 1);
    if (nextPageToken !== null && nextPageToken !== '') {
      setPageToken([...pageToken, nextPageToken]);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setLoading(true);
    setcurrpage(currpage - 1);
    if (pageToken.length > 1) {
      let pagetokens = pageToken;
      pagetokens.pop();
      setPageToken([...pagetokens]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currpage !== 1) setcurrpage(1);
    else fetchTransactionRequest();
  }, [fromDate, toDate, transactionStatus, transactionType]);

  const onCloseModal = () => {
    if (openDepositModal || openWithdrawModal) {
      return false;
    } else {
      fetchTransactionRequest();
    }
  };
  useEffect(() => {
    onCloseModal();
  }, [openDepositModal, openWithdrawModal]);

  const fromDateChangeHandler = (d: Moment) => {
    setfromDate(d);
  };

  const toDateChangeHandler = (d: Moment) => {
    setToDate(d);
  };

  const nextpageHandler = () => {
    setLoading(true);
    setcurrpage(currpage + 1);
  };

  const prevpageHandler = () => {
    setLoading(true);
    setcurrpage(currpage - 1);
  };

  const viewImage = (data) => {
    const base64ImageData = data;
    const contentType = data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

    const byteCharacters = atob(
      base64ImageData.substr(`data:${contentType};base64,`.length)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, '_blank');
  };

  return (
    // <IonRow>
    //   <IonCol sizeLg="12" sizeMd='12'>
    <div className="reports-ctn my-bets-ctn admin-pl-stmt-ctn transaction-stmt-ctn">
      <>
        <div className="header-ctn">
          <IonRow className="header-ctn-row">
            <div className="img-page-title-row">
              <div className="title">My Transactions</div>
            </div>
            <div className="title-border-line"></div>
            <div className="transaction-menu filters-row">
              <div className="transaction-menu-card web-view">
                <div className="transaction-menu-card-content">
                  <div className="filters-input txn-menu-card-content-div">
                    <IonLabel className="label">Transaction Type</IonLabel>
                    <IonSelect
                      value={transactionType}
                      placeholder="Select One"
                      interface="popover"
                      onIonChange={(e) => setTransactionType(e.detail.value)}
                    >
                      <IonSelectOption value={''}>{'All'}</IonSelectOption>
                      <IonSelectOption value={'DEPOSIT'}>
                        {'Deposit'}
                      </IonSelectOption>
                      <IonSelectOption value={'WITHDRAW'}>
                        {'Withdraw'}
                      </IonSelectOption>
                      {/* {Object.keys(OfflineTransactionTypeMap).map((opt, idx) => (
                      <IonSelectOption value={OfflineTransactionTypeMap[opt]}>
                        {opt}
                      </IonSelectOption>
                    ))} */}
                    </IonSelect>
                  </div>
                  <div className="txn-type-select trans-select-filter txn-menu-card-content-div">
                    <IonLabel className="label">Transaction Status</IonLabel>
                    <IonSelect
                      value={transactionStatus}
                      placeholder="Select One"
                      interface="popover"
                      onIonChange={(e) => setTransactionStatus(e.detail.value)}
                    >
                      <IonSelectOption value={''}>{'All'}</IonSelectOption>
                      <IonSelectOption value={'APPROVAL_PENDING'}>
                        {'Approval Pending'}
                      </IonSelectOption>
                      <IonSelectOption value={'IN_PROGRESS'}>
                        {'In Progress'}
                      </IonSelectOption>
                      <IonSelectOption value={'APPROVED'}>
                        {'Approved'}
                      </IonSelectOption>
                      <IonSelectOption value={'REJECTED'}>
                        {'Rejected'}
                      </IonSelectOption>
                      {/* {Object.keys(OfflineTransactionStatusTypeMap).map(
                      (opt, idx) => (
                        <IonSelectOption
                          value={OfflineTransactionStatusTypeMap[opt]}
                        >
                          {opt}
                        </IonSelectOption>
                      )
                    )} */}
                    </IonSelect>
                  </div>

                  {/* <div className="txn-type-select trans-select-filter"> */}
                  <div className="from-date-filter txn-menu-card-content-div">
                    <div className="date-label">From</div>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableFuture
                        className="date-filter date-control"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                        }}
                        disableToolbar
                        variant="inline"
                        format="DD/MM/yyyy"
                        minDate={moment().subtract(1, 'months').calendar()}
                        maxDate={toDate}
                        margin="normal"
                        id="from-date"
                        autoOk={true}
                        value={fromDate}
                        onChange={(e) => fromDateChangeHandler(e)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="to-date-filter txn-menu-card-content-div">
                    <div className="date-label">To</div>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableFuture
                        className="date-filter date-control"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                        }}
                        disableToolbar
                        variant="inline"
                        format="DD/MM/yyyy"
                        autoOk={true}
                        minDate={fromDate}
                        margin="normal"
                        id="to-date"
                        value={toDate}
                        onChange={(e) => toDateChangeHandler(e)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {/* </div> */}
                </div>
                <div className="payment-option-btns">
                  <Button
                    className="deposit-btn payment-btn web-view"
                    onClick={() => setOpenDepositModal(true)}
                  >
                    Deposit
                  </Button>
                  <Button
                    className="deposit-btn payment-btn mob-view"
                    onClick={() => history.push('/transaction/deposit')}
                  >
                    Deposit
                  </Button>
                  <Button
                    className="withdraw-btn payment-btn web-view"
                    onClick={() => setOpenWithdrawModal(true)}
                  >
                    Withdraw
                  </Button>
                  <Button
                    className="withdraw-btn payment-btn mob-view"
                    onClick={() => history.push('/transaction/withdraw')}
                  >
                    withdrawal
                  </Button>
                </div>
              </div>
            </div>
          </IonRow>
        </div>
        <div className="content-ctn light-bg">
          <div>
            <div className="admin-pl-filters-row filters-row mob-view transaction-filters-row">
              <div className="filter-col">
                <div className="txn-type-select trans-select-filter">
                  <IonLabel className="label">Transaction Type</IonLabel>
                  <IonSelect
                    value={transactionType}
                    placeholder="Select One"
                    interface="popover"
                    onIonChange={(e) => setTransactionType(e.detail.value)}
                  >
                    <IonSelectOption value={''}>{'All'}</IonSelectOption>
                    <IonSelectOption value={'DEPOSIT'}>
                      {'Deposit'}
                    </IonSelectOption>
                    <IonSelectOption value={'WITHDRAW'}>
                      {'Withdraw'}
                    </IonSelectOption>
                    {/* {Object.keys(OfflineTransactionTypeMap).map((opt, idx) => (
                      <IonSelectOption value={OfflineTransactionTypeMap[opt]}>
                        {opt}
                      </IonSelectOption>
                    ))} */}
                  </IonSelect>
                </div>
                <div className="txn-type-select trans-select-filter">
                  <IonLabel className="label">Transaction Status</IonLabel>
                  <IonSelect
                    value={transactionStatus}
                    placeholder="Select One"
                    interface="popover"
                    onIonChange={(e) => setTransactionStatus(e.detail.value)}
                  >
                    <IonSelectOption value={''}>{'All'}</IonSelectOption>
                    <IonSelectOption value={'APPROVAL_PENDING'}>
                      {'Approval Pending'}
                    </IonSelectOption>
                    <IonSelectOption value={'APPROVED'}>
                      {'Approved'}
                    </IonSelectOption>
                    <IonSelectOption value={'REJECTED'}>
                      {'Rejected'}
                    </IonSelectOption>
                    {/* {Object.keys(OfflineTransactionStatusTypeMap).map(
                      (opt, idx) => (
                        <IonSelectOption
                          value={OfflineTransactionStatusTypeMap[opt]}
                        >
                          {opt}
                        </IonSelectOption>
                      )
                    )} */}
                  </IonSelect>
                </div>
                <div className="from-date-filter filter-item">
                  <div className="filter-label">Start Date</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className="date-filter date-control"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      maxDate={toDate}
                      margin="normal"
                      id="from-date"
                      value={fromDate}
                      onChange={(e) => fromDateChangeHandler(e)}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="to-date-filter filter-item">
                  <div className="filter-label">End Date</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className="date-filter date-control"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      minDate={fromDate}
                      margin="normal"
                      id="to-date"
                      value={toDate}
                      onChange={(e) => toDateChangeHandler(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <Button
                  className="deposit-btn payment-btn mob-view"
                  onClick={() => history.push('/transaction/deposit')}
                >
                  Deposit
                </Button>
                <Button
                  className="withdraw-btn payment-btn mob-view"
                  onClick={() => history.push('/transaction/withdraw')}
                >
                  Withdraw
                </Button>
              </div>
            </div>
          </div>

          {errorMsg ? <Alert message={errorMsg} /> : ''}

          {loading ? (
            <Spinner />
          ) : (
            <>
              {/* <Tabs
                value={sectionTabVal}
                className="eam-ctn-header-tabs"
                onChange={(_, newValue) => {
                  setSectionTabVal(newValue);
                }}
              >
                <Tab label="Deposit" value={0} />
                <Tab label="Withdraw" value={1} />
              </Tabs> */}

              <div className="tbl-ctn">
                <TableContainer component={Paper} className="apl-table-ctn">
                  <Table className="apl-table">
                    <TableHead className="apl-table-header">
                      <TableRow>
                        <TableCell>Transaction Time</TableCell>
                        <TableCell>Transaction ID</TableCell>
                        {/* {sectionTabVal === 0 ? (
                          <TableCell>Reference ID</TableCell>
                        ) : null} */}
                        <TableCell>Transaction Type</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Approved Amount</TableCell>
                        <TableCell>Transaction Status</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Payment Method</TableCell>
                        {/* {sectionTabVal === 0 ? ( */}
                        {/* <TableCell>Image</TableCell> */}
                        {/* ) : null} */}
                      </TableRow>
                    </TableHead>
                    {transactionRequest && transactionRequest.length > 0 ? (
                      <>
                        <TableBody className="apl-table-body">
                          {transactionRequest.map((row, index) => (
                            <>
                              <TableRow className="apl-table-row" key={index}>
                                <TableCell align="left">
                                  {moment(row?.startTime).format(
                                    'DD-MM-YY, h:mm:ss A'
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.transactionId}
                                </TableCell>
                                {/* {sectionTabVal === 0 ? (
                                    <TableCell align="left">
                                      {row.referenceTransaction}
                                    </TableCell>
                                  ) : null} */}
                                <TableCell
                                  align="left"
                                  className="text-capitalize"
                                >
                                  {row.transactionType?.toLowerCase()}
                                </TableCell>
                                <TableCell align="left">
                                  {row.amount.toFixed(2)}
                                </TableCell>
                                <TableCell>
                                  {row?.approvedAmount
                                    ? row.approvedAmount
                                    : '-'}
                                </TableCell>
                                <TableCell align="left">
                                  {TransactionMap[row.txStatus]
                                    ? TransactionMap[row.txStatus]
                                    : row.txStatus}

                                  {TransactionMap[row.txStatus] ===
                                  'Rejected' ? (
                                    <Tooltip
                                      className="tooltip-icon"
                                      title={<span>{row?.remarks}</span>}
                                    >
                                      <IonIcon title={''} icon={eye}></IonIcon>
                                    </Tooltip>
                                  ) : null}
                                </TableCell>
                                <TableCell align="left">{row?.notes}</TableCell>
                                <TableCell align="left">
                                  {row?.paymentMethod}
                                </TableCell>
                                {/* {sectionTabVal === 0 ? ( */}

                                {/* ) : null} */}
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell className="no-data" colSpan={12}>
                          No records available
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
          <IonRow>
            {currpage === 1 || loading ? null : (
              <IonButton className="led-btn-prev" onClick={(e) => prevPage()}>
                Prev({currpage - 1})
              </IonButton>
            )}
            {transactionRequest?.length === pageSize && !loading ? (
              <IonButton className="led-btn-next" onClick={(e) => nextPage()}>
                Next({currpage + 1})
              </IonButton>
            ) : null}
          </IonRow>
        </div>
      </>
      <Backdrop className="backdrop-ctn" open={openDepositModal}>
        <Drawer
          anchor={'right'}
          open={openDepositModal}
          onClose={() => setOpenDepositModal(false)}
          className="light-bg-title game-rules-drawer"
          title=""
          // size="md"
        >
          <div className="game-rules-header">
            <div className="game-rules-title">Deposit</div>
            <div
              className="game-rules-close cursor"
              onClick={() => setOpenDepositModal(false)}
            >
              <CloseOutlined />
            </div>
          </div>
          <Deposit />
        </Drawer>
      </Backdrop>
      <Backdrop className="backdrop-ctn" open={openWithdrawModal}>
        <Drawer
          anchor={'right'}
          open={openWithdrawModal}
          onClose={() => setOpenWithdrawModal(false)}
          className="light-bg-title game-rules-drawer"
          title=""
          // size="md"
        >
          <div className="game-rules-header">
            <div className="game-rules-title">Withdraw</div>
            <div
              className="game-rules-close cursor"
              onClick={() => setOpenWithdrawModal(false)}
            >
              <CloseOutlined />
            </div>
          </div>
          <Withdrawal />
        </Drawer>
      </Backdrop>
    </div>
    //   </IonCol>
    // </IonRow>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
    currenciesAllowed: state.common.currenciesAllowed,
    openDepositModal: state.auth.openDepositModal,
    openWithdrawModal: state.auth.openWithdrawModal,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setOpenDepositModal: (val) => dispatch(setOpenDepositModal(val)),
    setOpenWithdrawModal: (val) => dispatch(setOpenWithdrawModal(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionRequest);
