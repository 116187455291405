import React, { useState, useEffect } from 'react';
import './Promotion.scss';
import { IonRow, IonCol } from '@ionic/react';
import { BRAND_NAME } from '../../constants/Branding';
import { useHistory, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import TabPanel from '../../components/TabPanel/TabPanel';

import { AuthResponse } from '../../models/api/AuthResponse';
import SVLS_API from '../../svls-api';
import DOMPurify from 'dompurify';
import { RootState } from '../../models/RootState';
import { connect } from 'react-redux';

type options = { name: string; value: string };

type PromotionProps = {};


const Promotion: React.FC<{ bonusEnabled: boolean }> = (props) => {
  const { bonusEnabled } = props;
  let brandname =
    BRAND_NAME?.charAt(0).toUpperCase() + BRAND_NAME.slice(1).toLowerCase();
  const pathLocation = useLocation();
  let history = useHistory();
  const [apiWebBanners, setApiWebBanners] = useState([]);
  const [apiMobBanners, setApiMobBanners] = useState([]);

  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    fetchBannerData();
  }, [])
  

  const fetchBannerData = async () => {
    let hostname: string[] = window.location.hostname.split('.');
    let DOMAIN_URL = hostname[hostname?.length - 2] + '.' + hostname[hostname.length - 1];
    // let DOMAIN_URL = 'quickexch.com';
    try {
      const response: AuthResponse = await SVLS_API.get(
        `/account/v2/books/${DOMAIN_URL}/banners`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            status: 'active',
            type: '*',
            category: 'Recommendedbanner',
          },
        }
      );
      let data = response?.data?.banners;
      let webdata = [];
      let mobiledata = [];
      if (data?.length > 0) {
        data.map((item) => {
          if (item.deviceType === 'desktop') {
            webdata.push(item);
          } else if (item.deviceType === 'mobile') {
            mobiledata.push(item);
          }
        });
        setApiWebBanners(webdata);
        setApiMobBanners(mobiledata);
      }
    } catch (err) {
      console.log(err);
      //setShowError(true);
      if (err.response && err.response.status === 400) {
        //setErrorText(err.response.data.message);
      } else {
        //setErrorText('Something went wrong');
      }
    }
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  return (
    <div className="promotion_page">
      {/* <SEO
        title={BRAND_NAME}
        name={'Promotions page'}
        description={'Promotions page'}
        type={'Promotions page'}
        link={pathLocation?.pathname}
      /> */}
      <IonRow>
        <IonCol sizeLg="12">
          {apiMobBanners &&
          apiMobBanners?.length > 0 &&
          apiWebBanners &&
          apiWebBanners?.length > 0 ? (
            <>
              <div className="web-content">
                <Tabs
                  value={tabValue}
                  onChange={(_, newValue) => {
                    setTabValue(newValue);
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                  className="types-tabs"
                >
                  {apiWebBanners.map((banner, index) => (
                    <Tab label={banner?.title} value={index} />
                  ))}
                </Tabs>
                {apiWebBanners?.map((banner, index) => (
                  <TabPanel value={tabValue} index={index}>
                    <div className="web-banner">
                      <img src={banner?.publicUrl} />
                    </div>
                    <div className="card_promotion">
                      {/* <div className="mob-banner">
                          <img src={SignupMobBanner} />
                        </div> */}
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          banner?.displayContent
                        )}
                      ></div>
                    </div>
                  </TabPanel>
                ))}
              </div>
              <div className="mob-content">
                <Tabs
                  value={tabValue}
                  onChange={(_, newValue) => {
                    setTabValue(newValue);
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                  className="types-tabs"
                >
                  {apiWebBanners.map((banner, index) => (
                    <Tab label={banner?.title} value={index} />
                  ))}
                </Tabs>
                {apiMobBanners?.map((banner, index) => (
                  <TabPanel value={tabValue} index={index}>
                    <div className="mob-banner">
                      <img src={banner?.publicUrl} />
                    </div>
                    <div className="card_promotion">
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          banner?.displayContent
                        )}
                      ></div>
                    </div>
                  </TabPanel>
                ))}
              </div>
            </>
          ) : null
          }
        </IonCol>
      </IonRow>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    bonusEnabled: state.common.domainConfig.bonus,
  };
};
export default connect(mapStateToProps)(Promotion);