export const IS_ADMIN_REPORTS_URL = 'IS_ADMIN_REPORTS_URL';
export const IS_ADMIN_RISKMGMT_URL = 'IS_ADMIN_RISKMGMT_URL';
export const IS_HOUSE_URL = 'IS_HOUSE_URL';
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export const CASINO_GAME_SELECTED = 'CASINO_GAME_SELECTED';
export const SET_DC_GAME_URL = 'SET_DC_GAME_URL';
export const SET_LIVESTREAM_URL = 'SET_LIVESTREAM_URL';
export const SET_PLAY_STREAM = 'SET_PLAY_STREAM';
export const SET_ALLOWED_CONFIG = 'SET_ALLOWED_CONFIG';
export const ENABLE_COMMISSION = 'ENABLE_COMMISSION';
export const SET_DOMAIN_CONFIG = 'SET_DOMAIN_CONFIG';
export const TRIGGER_FETCH_BALANCE = 'TRIGGER_FETCH_BALANCE';
