import API from '../../api';
import { logout } from '../auth/authActions';

import {
  IS_ADMIN_REPORTS_URL,
  IS_ADMIN_RISKMGMT_URL,
  IS_HOUSE_URL,
  TOGGLE_DARK_MODE,
  CASINO_GAME_SELECTED,
  SET_DC_GAME_URL,
  SET_LIVESTREAM_URL,
  SET_PLAY_STREAM,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
  SET_DOMAIN_CONFIG,
  TRIGGER_FETCH_BALANCE,
} from './commonActionTypes';

import { CurrencyDetails } from '../../models/CurrencyDetails';
import { DomainConfig } from '../../constants/DomainConfig';

export const activateReportsTab = (value: boolean) => {
  return {
    type: IS_ADMIN_REPORTS_URL,
    payload: value,
  };
};

export const activateRiskMgmtTab = (value: boolean) => {
  return {
    type: IS_ADMIN_RISKMGMT_URL,
    payload: value,
  };
};

export const activateHouseTab = (value: boolean) => {
  return {
    type: IS_HOUSE_URL,
    payload: value,
  };
};

export const toggleDarkMode = (value: string) => {
  return {
    type: TOGGLE_DARK_MODE,
    payload: value,
  };
};

export const setPlayStream = (value: boolean) => {
  return {
    type: SET_PLAY_STREAM,
    payload: value,
  };
};

export const casinoGameSelected = (casinoGame: {
  id: string;
  name: string;
}) => {
  return {
    type: CASINO_GAME_SELECTED,
    payload: casinoGame,
  };
};

export const setDcGameUrl = (url: string) => {
  return {
    type: SET_DC_GAME_URL,
    payload: url,
  };
};

export const setLivestreamUrl = (url: string) => {
  return {
    type: SET_LIVESTREAM_URL,
    payload: url,
  };
};

export const setAllowedConfig = (allowedConfig: number) => {
  return {
    type: SET_ALLOWED_CONFIG,
    payload: allowedConfig,
  };
};

export const enableCommission = (commission: boolean) => {
  return {
    type: ENABLE_COMMISSION,
    payload: commission,
  };
};

export const setDomainConfig = (config: DomainConfig) => {
  return {
    type: SET_DOMAIN_CONFIG,
    payload: config,
  };
};

export const triggerFetchBalance = (eventTime: number) => {
  return {
    type: TRIGGER_FETCH_BALANCE,
    payload: eventTime,
  };
};
