export const ContactList: { [key: string]: string } = {
  KingsOdd: '+44 7883 309261 | +44 7418 357121',
};

export const ShowCasinoModal: { [key: string]: boolean } = {
  DhanEXCH: false,
};

export const ShowThemeToggle: { [key: string]: boolean } = {
  Bet60Exchange: false,
};

export const EzugiChanges: { [key: string]: boolean } = {
  chal247: true,
};
