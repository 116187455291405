import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
import MyBetsImg from '../../assets/images/icons/my-bets.svg';
import MyWalletImg from '../../assets/images/icons/wallet.svg';
import AccStmtImg from '../../assets/images/icons/acc_stmt.svg';
import PLStmtImg from '../../assets/images/icons/dollar.svg';
import GraphImg from '../../assets/images/icons/upgraph.svg';
import LockImg from '../../assets/images/icons/lock.svg';
import LogoutImg from '../../assets/images/icons/logout.svg';
// import UserImg from '../../assets/images/home/footer/user.svg';
import UserImg from '../../assets/images/icons/user.svg';
import { IonCol, IonIcon, IonRow } from '@ionic/react';
import './Dashboard.scss';
import { NavLink } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import Modal from '../Modal/Modal';
import ChangePassword from '../ChangePassword/ChangePassword';
import { getCurrencyTypeFromToken, logout } from '../../store';
import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermission';
import { demoUsers } from '../../util/stringUtil';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import { DomainConfig } from '../../constants/DomainConfig';

type StoreProps = {
  balance: number;
  allowedConfig: number;
  commissionEnabled: boolean;
  logout: () => void;
  domainConfig: DomainConfig;
};

const DashboardView: React.FC<StoreProps> = (props) => {
  const { balance, allowedConfig, commissionEnabled, logout, domainConfig } = props;
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const dashboardItems = [
    { key: 'my-bets', name: 'My Bets', imgSrc: MyBetsImg, url: 'my_bets' },
    {
      key: 'my-wallet',
      name: 'My Wallet',
      imgSrc: MyWalletImg,
      url: '/account/my_wallet',
    },
    {
      key: 'acc-stmt',
      name: 'Account Statement',
      imgSrc: AccStmtImg,
      url: '/account/account_statement',
    },
    {
      key: 'pl-stmt',
      name: 'P/L Statement',
      imgSrc: PLStmtImg,
      url: '/account/pl_statement',
    },
  ];

  const commonDashboardItems = [
    {
      key: 'set-btn-vars',
      name: 'Set Button Variables',
      imgSrc: GraphImg,
      url: '/account/set-button-variables',
    },
    {
      key: 'edit-profile',
      name: 'Edit Profile',
      imgSrc: UserImg,
      url: '/account/profile',
    },
  ];

  return (
    <div className="dashboard-ctn">
      <div className="header-content">
        <div className="user-details-section">
          <div className="name">{sessionStorage.getItem('username')}</div>
          <div className="user-balance">
            Bal: {Number(balance / cFactor).toFixed(2)}
          </div>
        </div>
        <div className="logout-section">
          <div className="logout-item" onClick={() => logout()}>
            <IonIcon className="logout-icon" src={LogoutImg} />
            Logout
          </div>
        </div>
      </div>
      <IonRow className="dashboard-cards-ctn">
        {dashboardItems.map((item) => (
          <IonCol
            className="dashboard-item"
            key={item.key}
            sizeXs="6"
            sizeSm="4"
            sizeMd="3"
          >
            <NavLink to={item.url} className="dashboard-item-link">
              <IonIcon
                className={
                  item.key === 'game-report'
                    ? 'dashboard-item-icon sports-icon'
                    : 'dashboard-item-icon'
                }
                color="primary"
                src={item.imgSrc}
              />
              <span className="dashboard-item-text">{item.name}</span>
            </NavLink>
          </IonCol>
        ))}

        {(allowedConfig & CONFIG_PERMISSIONS.sports) !== 0 ? (
          <IonCol
            sizeXs="6"
            sizeSm="4"
            sizeMd="3"
            className="dashboard-item change-pwd-col"
          >
            <NavLink
              to={'/account/game_report'}
              className="dashboard-item-link"
            >
              <IonIcon
                className="dashboard-item-icon"
                color="primary"
                src={MyWalletImg}
              />
              <span className="dashboard-item-text">Game Report</span>
            </NavLink>
          </IonCol>
        ) : null}

        {(allowedConfig & CONFIG_PERMISSIONS.sports) !== 0 &&
        (allowedConfig & CONFIG_PERMISSIONS.commission) !== 0 &&
        commissionEnabled ? (
          <IonCol
            sizeXs="6"
            sizeSm="4"
            sizeMd="3"
            className="dashboard-item change-pwd-col"
          >
            <NavLink to={'/commission_report'} className="dashboard-item-link">
              <IonIcon
                className="dashboard-item-icon"
                color="primary"
                src={MyWalletImg}
              />
              <span className="dashboard-item-text">Commission Report</span>
            </NavLink>
          </IonCol>
        ) : null}
                {domainConfig.bonus ? (
          <>
            <IonCol
              className="dashboard-item"
              key={'bonus-stmt'}
              sizeXs="12"
              sizeSm="12"
              sizeMd="3"
            >
              <NavLink to={'/bonus_statement'} className="dashboard-item-link">
                <IonIcon
                  className={'dashboard-item-icon'}
                  color="primary"
                  src={AccStmtImg}
                />
                <span className="dashboard-item-text">Bonus Statement</span>
              </NavLink>
            </IonCol>

            <IonCol
              className="dashboard-item"
              key={'turnover-stmt'}
              sizeXs="12"
              sizeSm="12"
              sizeMd="3"
            >
              <NavLink
                to={'/bonus/turnover_history'}
                className="dashboard-item-link"
              >
                <IonIcon
                  className={'dashboard-item-icon'}
                  color="primary"
                  src={AccStmtImg}
                />
                <span className="dashboard-item-text">Turnover History</span>
              </NavLink>
            </IonCol>
          </>
        ) : null}

        {commonDashboardItems.map((item) => (
          <IonCol
            className="dashboard-item"
            key={item.key}
            sizeXs="6"
            sizeSm="4"
            sizeMd="3"
          >
            <NavLink to={item.url} className="dashboard-item-link">
              <IonIcon
                className={
                  item.key === 'game-report'
                    ? 'dashboard-item-icon sports-icon'
                    : 'dashboard-item-icon'
                }
                color="primary"
                src={item.imgSrc}
              />
              <span className="dashboard-item-text">{item.name}</span>
            </NavLink>
          </IonCol>
        ))}
        {sessionStorage.getItem('username') &&
        demoUsers.includes(
          sessionStorage.getItem('username').toLowerCase()
        ) ? null : (
          <IonCol
            sizeXs="6"
            sizeSm="4"
            sizeMd="3"
            className="dashboard-item change-pwd-col"
            onClick={() => setChangePwdMOdal(true)}
          >
            <div className="dashboard-item-link">
              <IonIcon
                className="dashboard-item-icon"
                color="primary"
                src={LockImg}
              />
              <span className="dashboard-item-text">Change Password</span>
            </div>
          </IonCol>
        )}
      </IonRow>
      <div className="logout-item-row"></div>
      <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
        <Modal
          open={changePwdMOdal}
          closeHandler={() => setChangePwdMOdal(false)}
          title="Change Password"
          size="xs"
        >
          <ChangePassword
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => {}}
          />
        </Modal>
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    balance: state.auth.balanceSummary.balance,
    allowedConfig: state.common.allowedConfig,
    commissionEnabled: state.common.commissionEnabled,
    domainConfig: state.common.domainConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
